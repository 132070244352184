export default function useDefaultTShirtSizes() {
  return [
    'Youth XS',
    'Youth SM',
    'Youth MD',
    'Youth LG',
    'SM',
    'MD',
    'LG',
    'XL',
    '2XL',
    '3XL',
    '4XL',
  ]
}
